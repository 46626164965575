
import styles from './Sub2ResultsPage.module.css'

import FlockNumbers from './FlockNumbers'
import Leaflet from './Leaflet';
/*import { useEffect, useState } from 'react';*/

function Sub2ResultsPage() {

    
    /*const [flock1, setFlock1] = useState([])
    const [flock2, setFlock2] = useState([])
    const [flock3, setFlock3] = useState([])
    const [stable1, setStable1] = useState([])
    const [stable2, setStable2] = useState([])
    const [stable3, setStable3] = useState([])
    const [points1, setpoints1] = useState([])
    const [points2, setpoints2] = useState([])
    const [points3, setpoints3] = useState([])
    const [flockqty, setFlockqty] = useState([])
    const [animalqty, setAnimalqty] = useState([])*/

    /*useEffect(() => {
        fetch('http://localhost:3002/flock/overview', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // Verifica se os rebanhos existem no array antes de acessar
                if (data[0] && data[0].area_poly && data[0].stable) {
                    //const areaPoly1 = data[0].area_poly.map((coord) => [coord.lat, coord.lon]);
                   // setFlock1(areaPoly1);
                    setStable1(data[0].stable.map((stable) =>[stable.lat, stable.lon]));
                    setpoints1(data[0].qty_class)
                }

                if (data[1] && data[1].area_poly && data[1].stable) {
                    //const areaPoly2 = data[1].area_poly.map((coord) => [coord.lat, coord.lon]);
                    //setFlock2(areaPoly2);
                    setStable2(data[1].stable.map((stable) =>[stable.lat, stable.lon]));
                    setpoints2(data[1].qty_class)
                }

                if (data[2] && data[2].area_poly && data[2].stable) {
                    //const areaPoly3 = data[2].area_poly.map((coord) => [coord.lat, coord.lon]);
                    ////setFlock3(areaPoly3);
                    setStable3(data[2].stable.map((stable) =>[stable.lat, stable.lon]));
                    setpoints3(data[2].qty_class)
                }
                return(data)
            })
            .then((data) => {
                if( data.length > 0 )  {
                    const qty = data.length
                    setFlockqty(qty)
                   // setAnimalqty(qty*50)
                    
            }})
            .catch((error) => console.error('Erro ao buscar dados:', error));
    }, []);*/
    
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h2> Mapeamento de Pastoreio </h2>
            </div>
            
            <div className={styles.FlockNumbers}>
                <FlockNumbers title={"Rebanhos"} num={"..."} /> {/*flockqty*/}
                <FlockNumbers title={"Média de animais"} num={"..."} />
                <FlockNumbers title={"Pontos coletados"} num={"..."} /> {/*points1+points2+points3*/}
                <FlockNumbers title={"Pastoreio (km)"} num={"..."} />
                <FlockNumbers title={"Tempo de pastoreio (h)"} num={"..."} />
                <FlockNumbers title={"Dias de pastoreio"} num={"..."} />
            </div>

            <div className={styles.title}>
                <h2> Etnografia </h2>
            </div>
            <div className={styles.FlockNumbers}>
                <FlockNumbers title={"Pastores"} num={"6"} />
                <FlockNumbers title={"Obervação"} num={"2 anos"} />
                <FlockNumbers title={"Caminhadas (km)"} num={"..."} />
                <FlockNumbers title={"Horas de caminhada"} num={"..."} />
            </div>

            <div className={styles.leaflet}>
                <Leaflet 
                flock1 = {[]} stable1 = {null} /*stable1*/
                flock2 = {[]} stable2 = {null} /*stable2*/
                flock3 = {[]} stable3 = {null} /*stable3*/
                />
            </div>
            <br></br>
        </div>
    )
}

export default Sub2ResultsPage;